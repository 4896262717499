import { useDispatch, useSelector, connect } from 'react-redux';

import { devicesActions } from './store';
import { useEffectAsync } from './reactHelper';

const CachingController = () => {
  const authenticated = useSelector((state) => !!state.session.driver);
  const dispatch = useDispatch();

  useEffectAsync(async () => {
    if (authenticated) {
      console.log('Fetching devices');
      const response = await fetch('/api/operators/devices');
      if (response.ok) {
        const devices = await response.json();
        console.log(devices);
        dispatch(devicesActions.refresh(devices));
      } else {
        throw Error(await response.text());
      }
    }
    return null;
  }, [authenticated]);

  return null;
};

export default connect()(CachingController);
