/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Grid, IconButton, Accordion, AccordionSummary, AccordionDetails,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMeasure } from 'react-use';
import {
  formatDistance, formatHours, formatNumericHours, formatSpeed, formatTime, formatVolume,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ReportFilter from './components/ReportFilter';
import usePersistedState from '../common/util/usePersistedState';
import ColumnSelect from './components/ColumnSelect';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import HeavyDutyCard from '../common/components/HeavyDutyCard';
import HeavyDutyCardShimmer from '../common/components/HeavyDutyCardShimmer';
import { prefixString } from '../common/util/stringUtils';
import MapView from '../map/core/MapView';
import MapGeofence from '../map/MapGeofence';
import MapPositions from '../map/MapPositions';
import MapCamera from '../map/MapCamera';
import PositionValue from '../common/components/PositionValue';
import theme from '../common/theme';

const columnsArray = [
  ['hours', 'reportEngineHours'],
  ['fuel_consumption', 'reportSpentFuel'],
  ['distance', 'sharedDistance'],
  ['alarmHighRpm', 'alarmHighRpm'],
  ['alarmTemperature', 'alarmTemperature'],
  ['alarmHighOilPressure', 'alarmHighOilPressure'],
];
const columnsMap = new Map(columnsArray);

const eventColumnsArray = [
  ['eventTime', 'positionFixTime'],
  ['type', 'sharedType'],
  ['attributes', 'commandData'],
];
const eventColumnsMap = new Map(eventColumnsArray);

const HeavyDutyPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('heavyDutyColumns', ['hours', 'fuel_consumption', 'distance']);
  const eventColumns = ['eventTime', 'type', 'attributes'];
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [position, setPosition] = useState(null);

  const [openDetails, setOpenDetails] = useState(false);

  useEffectAsync(async () => {
    if (selectedItem) {
      const response = await fetch(`/api/positions?id=${selectedItem.positionId}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setPosition(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    } else {
      setPosition(null);
    }
  }, [selectedItem]);

  const handleSubmit = useCatch(async ({ from, to, groupIds, deviceIds }) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
      /* const response = await fetch(`/api/reports/devices?${query.toString()}`); */
      const response = await fetch(`/api/analytics?${query}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const handleOpenDetails = (e) => {
    if (selectedDevice) {
      setOpenDetails((prev) => !prev);
    }
  };

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'fuel_consumption':
        return formatVolume(item[key], volumeUnit, t);
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t);
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  const [layoutRef, { width: layoutWidth }] = useMeasure();
  const [gridRef, { width: gridWidth }] = useMeasure();

  const columnsPerRow = () => {
    const paddingSpacing = 2 * parseInt(theme.spacing(2), 10);
    const gapSpacing = parseInt(theme.spacing(2), 10);
    const cardWidth = theme.dimensions.popupMaxWidth;
    const baseColumns = Math.floor((gridWidth - paddingSpacing) / cardWidth);
    const gapedWidth = (baseColumns * cardWidth) + (gapSpacing * (baseColumns - 1));
    return gapedWidth < gridWidth ? baseColumns : baseColumns - 1;
  };

  const computeMissingColumns = () => {
    const totalItems = items.length;
    const columns = columnsPerRow();
    const missingColumns = totalItems % columns;
    return missingColumns === 0 ? 0 : columns - missingColumns;
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container} ref={layoutRef}>
        {selectedItem && (
          <div className={classes.containerMapSticky}>
            <MapView>
              <MapGeofence />
              {position && <MapPositions positions={[position]} titleField="fixTime" />}
            </MapView>
            {position && <MapCamera latitude={position.latitude} longitude={position.longitude} />}
          </div>
        )}
        <div className={classes.header}>
          <ReportFilter handleSubmit={handleSubmit} multiDevice includeGroups deviceType="heavy-duty" isDaily>
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </ReportFilter>
        </div>
        <Grid
          columns={columnsPerRow()}
          container
          columnGap={2}
          rowGap={2}
          padding={2}
          sx={{ pb: 8 }}
          justifyContent={items.length < columnsPerRow() ? 'flex-start' : 'center'}
          ref={gridRef}
        >
          {
            loading && items.length === 0 && (
              [...Array(5)].map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs="auto" key={idx}>
                  <HeavyDutyCardShimmer columns={columns} />
                </Grid>
              )))
          }

          {items.map((device) => (
            <Grid item xs="auto" key={device.deviceid}>
              {
                loading ?
                  <HeavyDutyCardShimmer columns={columns} />
                  : (
                    <HeavyDutyCard
                      deviceId={device.deviceid}
                      columns={columns}
                      position={device}
                      columnsMap={columnsMap}
                      selectable
                      selectAction={setSelectedDevice}
                      selected={selectedDevice?.id === device.deviceid}
                    />
                  )
              }
            </Grid>
          ))}
          {computeMissingColumns() > 0 && [...Array(computeMissingColumns())].map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs="auto" key={idx}>
              <Box sx={{ width: theme.dimensions.popupMaxWidth, height: 2 }} />
            </Grid>
          ))}
        </Grid>
        <Accordion
          expanded={openDetails && selectedDevice !== null}
          onChange={handleOpenDetails}
          sx={{
            position: 'fixed',
            bottom: 0,
            width: layoutWidth,
            display: 'None',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnAction} />
                  {
                    // eslint-disable-next-line react/no-array-index-key
                    eventColumns.map((key, idx) => (<TableCell key={key + idx}>{t(eventColumnsMap.get(key))}</TableCell>))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? selectedDevice?.refuels?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.columnAction} padding="none">
                      {item.positionId ? selectedItem === item ? (
                        <IconButton size="small" onClick={() => setSelectedItem(null)}>
                          <GpsFixedIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton size="small" onClick={() => setSelectedItem(item)}>
                          <LocationSearchingIcon fontSize="small" />
                        </IconButton>
                      ) : ''}
                    </TableCell>
                    {eventColumns.map((key, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableCell key={item + idx}>
                        {/* FIX TIME FORMAT */}
                        {formatValue(item, key)}
                      </TableCell>
                    ))}
                  </TableRow>
                )) : (<TableShimmer columns={columns.length + 1} />)}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </div>
    </PageLayout>
  );
};

export default HeavyDutyPage;
