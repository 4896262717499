/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Grid, IconButton, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import { useSelector } from 'react-redux';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMeasure } from 'react-use';
import {
  formatDistance, formatHours, formatNumericHours, formatSpeed, formatTime, formatVolume,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ReportFilter from './components/ReportFilter';
import usePersistedState from '../common/util/usePersistedState';
import ColumnSelect from './components/ColumnSelect';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import HeavyDutyCard from '../common/components/HeavyDutyCard';
import HeavyDutyCardShimmer from '../common/components/HeavyDutyCardShimmer';
import { prefixString } from '../common/util/stringUtils';
import MapView from '../map/core/MapView';
import MapGeofence from '../map/MapGeofence';
import MapPositions from '../map/MapPositions';
import MapCamera from '../map/MapCamera';
import PositionValue from '../common/components/PositionValue';

const columnsArray = [
  ['hours', 'reportEngineHours'],
  ['fuel_consumption', 'reportSpentFuel'],
  ['distance', 'sharedDistance'],
  ['alarmHighRpm', 'alarmHighRpm'],
  ['alarmTemperature', 'alarmTemperature'],
  ['alarmHighOilPressure', 'alarmHighOilPressure'],
];
const columnsMap = new Map(columnsArray);

const eventColumnsArray = [
  ['eventTime', 'positionFixTime'],
  ['type', 'sharedType'],
  ['attributes', 'commandData'],
];
const eventColumnsMap = new Map(eventColumnsArray);

const CorporatePage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('heavyDutyColumns', ['hours', 'fuel_consumption', 'distance']);
  const eventColumns = ['eventTime', 'type', 'attributes'];
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [position, setPosition] = useState(null);

  const [openDetails, setOpenDetails] = useState(false);

  useEffectAsync(async () => {
    if (selectedItem) {
      const response = await fetch(`/api/positions?id=${selectedItem.positionId}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setPosition(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    } else {
      setPosition(null);
    }
  }, [selectedItem]);

  const handleSubmit = useCatch(async ({ from, to, groupIds, deviceIds }) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
      /* const response = await fetch(`/api/reports/devices?${query.toString()}`); */
      const response = await fetch(`/api/analytics?${query}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const [layoutRef, { width: layoutWidth }] = useMeasure();

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container} ref={layoutRef}>
        {selectedItem && (
          <div className={classes.containerMapSticky}>
            <MapView>
              <MapGeofence />
              {position && <MapPositions positions={[position]} titleField="fixTime" />}
            </MapView>
            {position && <MapCamera latitude={position.latitude} longitude={position.longitude} />}
          </div>
        )}
        <div className={classes.header}>
          {/* <ReportFilter handleSubmit={handleSubmit} multiDevice includeGroups deviceType="heavy-duty" isDaily>
            <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} />
          </ReportFilter> */}
        </div>
        <Grid container columnGap={2} rowGap={2} padding={0} sx={{ flex: 1 }}>
          <iframe
            title="Corporate Report"
            width="100%"
            height="100%"
            className={classes.iframe}
            src="https://lookerstudio.google.com/embed/reporting/ac4f7c16-8d8e-470b-b5dd-8e7b666688fa/page/rxTyD"
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          >
          </iframe>
        </Grid>
      </div>
    </PageLayout>
  );
};

export default CorporatePage;
