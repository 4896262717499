import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableVirtuoso } from 'react-virtuoso';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table,
  TableHead,
  TableRow,
  TableBody,
};

const VirtualizedTable = ({ columns, rowContent, rows }) => (
  <div style={{ height: '100%', width: '100%' }}>
    <TableVirtuoso
      data={rows}
      components={VirtuosoTableComponents}
      fixedHeaderContent={columns}
      itemContent={rowContent}
    />
  </div>
);

export default VirtualizedTable;
