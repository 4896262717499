import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardMedia,
  CardActionArea,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from './LocalizationProvider';
import {
  formatDistance, formatHours, formatNumericHours, formatTime, formatVolume,
} from '../util/formatter';
import { useAttributePreference, usePreference } from '../util/preferences';
import { mapIconKey, mapIcons } from '../../map/core/preloadImages';
import theme from '../theme';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    backgroundColor: 'white',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
  selectedCard: {
    backgroundColor: theme.palette.colors.primarySelected,
  },
  unSelectedCard: {
    backgroundColor: theme.palette.secondary.selected,
  },

}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const HeavyDutyCard = ({
  deviceId,
  position,
  desktopPadding = 0,
  columns,
  columnsMap,
  selectable,
  selectAction = () => { },
  selected = false,
}) => {
  const classes = useStyles({ desktopPadding });
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const device = useSelector((state) => state.devices.items[deviceId]);
  const deviceImage = device?.attributes?.deviceImage;

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key] || (item.attributes && item.attributes[key]), 'minutes', hours12);
      case 'duration':
        return formatHours(item[key] || (item.attributes && item.attributes[key]));
      case 'hours':
        return formatNumericHours(item[key] || (item.attributes && item.attributes[key]), t);
      case 'fuel_consumption':
        return formatVolume(item[key] || (item.attributes && item.attributes[key]), volumeUnit, t);
      case 'totalDistance':
      case 'distance':
        return formatDistance(item[key] || (item.attributes && item.attributes[key]), distanceUnit, t);

      default:
        return item[key] || (item.attributes && item.attributes[key]);
    }
  };

  return (

    <Card elevation={3} className={classes.card}>
      <CardActionArea
        className={selected ? classes.selectedCard : classes.unSelectedCard}
        disabled={!selectable}
        onClick={() => (selected ? selectAction(null) : selectAction(device))}
      >
        {deviceImage ? (
          <CardMedia
            className={classes.media}
            image={`/api/media/${device.uniqueId}/${deviceImage}`}
          />
        ) : (
          <CardMedia
            className={classes.media}
            sx={{ paddingX: theme.spacing(6) }}
            image={mapIcons[mapIconKey(device?.category)]}
            component="img"
          />
        )}
        <div className={classes.header}>
          <Typography variant="body2" color="textPrimary" fontWeight="bold">
            {device?.name}
          </Typography>
        </div>

        {position && (
          <CardContent className={classes.content}>
            <Table size="small" classes={{ root: classes.table }}>
              <TableBody>
                {columns.filter((key) => position.hasOwnProperty(key) || position.attributes?.hasOwnProperty(key)).map((key) => (
                  <StatusRow
                    key={key}
                    name={columnsMap.get(key) ? t(columnsMap.get(key)) : key}
                    content={formatValue(position, key)}
                  />
                ))}
                {columns.filter((key) => !position.hasOwnProperty(key) && !position.attributes?.hasOwnProperty(key)).map((key) => (
                  <StatusRow
                    key={key}
                    name={columnsMap.get(key) ? t(columnsMap.get(key)) : key}
                    content=""
                  />
                ))}

              </TableBody>
            </Table>
          </CardContent>
        )}
        <CardActions classes={{ root: classes.actions }} disableSpacing />
      </CardActionArea>
    </Card>
  );
};

export default HeavyDutyCard;
