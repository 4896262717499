export default [
  'default',
  'animal',
  'bicycle',
  'boat',
  'bus',
  'car',
  'crane',
  'helicopter',
  'motorcycle',
  'offroad',
  'person',
  'pickup',
  'plane',
  'ship',
  'tractor',
  'train',
  'tram',
  'trolleybus',
  'truck',
  'van',
  'scooter',
  'miniloader',
  'excavator',
  'backhoeLoader',
  'pileDriver',
  'auxCranePileDriver',
  'stationaryConcretePump',
  'dumpTruck',
  'vibratorySoilCompactor',
  'courier',
];
